/*
@include media-breakpoint-down(lg) {
  html {
    font-size: 90%;
  }
}

@include media-breakpoint-down(md) {
  html {
    font-size: 85%;
  }
}

@include media-breakpoint-down(sm) {
  html {
    font-size: 85%;
  }
}
*/
.blockHCenter, .instructionCopy, .headlineCopy {
  left: 0;
  right: 0;
  margin: auto;
  display: block; }

html, body {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0; }

body {
  font-family: Arial, Helvetica, sans-serif;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

a {
  color: blue;
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto; }

img {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

#background, .pageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(../images/bg.jpg);
  background-repeat: no-repeat;
  background-size: cover; }
  @media (min-width: 600px) {
    #background, .pageOverlay {
      background-image: url(../images/bg_big.jpg); } }

#wrapper {
  width: 100%;
  height: 100%;
  max-width: 675px;
  visibility: hidden;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.photoWrapper {
  position: relative;
  width: 100%;
  height: 100%; }
  .photoWrapper .photoHolder {
    position: absolute;
    bottom: 9.8%;
    height: 73.4%;
    display: block;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .photoWrapper .photoHolder#resultPhotoHolder {
      bottom: -73%; }

.failCopy {
  top: 57%;
  height: 20%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.photo {
  height: 100%;
  width: auto; }

.trayButtons {
  position: relative;
  width: 100%;
  height: 56%;
  margin-top: 2.5%;
  visibility: hidden;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }
  .trayButtons .trayButton {
    position: absolute;
    height: 100%;
    width: auto;
    opacity: .5;
    -webkit-transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.9);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.9); }
    .trayButtons .trayButton#undoButton {
      left: 0; }
    .trayButtons .trayButton#submitButton {
      right: 0; }
    .trayButtons .trayButton.enabled {
      opacity: 1;
      cursor: pointer; }
      .no-touch .trayButtons .trayButton.enabled:hover {
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }

.floor {
  position: absolute;
  bottom: 0;
  height: 11.7%;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.logoLove {
  position: absolute;
  bottom: 6%;
  left: 4.5%;
  height: 70%;
  max-height: 94px; }

.footerLogos {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 11.7%;
  width: 100%; }

.logoWwf {
  position: absolute;
  bottom: 21%;
  right: 4.2%;
  height: 46.3%;
  max-height: 62px; }

.lockupWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden; }
  .lockupWrapper .logoWwfLockup {
    position: absolute;
    top: 7.5%;
    height: 5.5%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
  .lockupWrapper .lockupHeadline {
    position: absolute;
    top: 19.2%;
    height: 3.2%;
    display: none;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
  .lockupWrapper .envelope {
    position: absolute;
    top: 32%;
    height: 36%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
  .lockupWrapper .lockupButton {
    position: absolute;
    height: 6.3%;
    cursor: pointer;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
    .lockupWrapper .lockupButton#startOverButton {
      top: 21%; }
    .lockupWrapper .lockupButton#shareButton {
      top: 75%; }
    .lockupWrapper .lockupButton#downloadButton {
      top: 83%; }
      .lockupWrapper .lockupButton#downloadButton.hidden {
        display: none; }

.introWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .introWrapper .logoWwfIntro {
    position: absolute;
    top: 9%;
    height: 5.5%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
  .introWrapper .introHeadline {
    position: absolute;
    top: 75.5%;
    height: 13%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
  .introWrapper .introHeart {
    position: absolute;
    top: 45%;
    height: 23%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }
  .introWrapper .logoLoveLetters {
    position: absolute;
    top: 19.5%;
    height: 18%;
    position: absolute;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0); }

.trayBottom {
  bottom: 10.8%;
  height: 8.9%;
  text-align: center;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.trayTop {
  top: 17%;
  height: 9.5%;
  text-align: center;
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0); }

.letterBg {
  height: 100%;
  width: auto;
  display: block; }

.letterHolder {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  overflow: visible;
  font-size: 0;
  text-align: left; }

.invisible {
  visibility: hidden; }

.letter {
  height: 100%;
  width: auto;
  display: inline-block;
  position: relative; }

.letterTop {
  position: absolute; }

.instructionCopy {
  position: absolute;
  width: 100%;
  height: 2.05%;
  top: 74.5%;
  -webkit-filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.4));
          filter: drop-shadow(4px 4px 3px rgba(0, 0, 0, 0.4)); }

.headlineCopy {
  position: absolute;
  width: 100%;
  height: 3.1%;
  top: 9.2%; }

.icon_close {
  position: absolute;
  top: 0;
  right: 0;
  width: auto;
  height: 2.8%;
  padding: 3.4%;
  display: none;
  cursor: pointer; }

.statusIcons {
  position: absolute;
  top: 0;
  left: 0;
  width: 7.5%;
  height: 7.5%;
  z-index: 1001; }

.statusIcon {
  width: 100%;
  height: auto;
  position: absolute;
  top: -230%;
  left: -45%; }

.pageOverlay {
  opacity: .7;
  visibility: hidden;
  opacity: 0; }

.dot {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 0;
  background: url(../images/heart.svg);
  background-size: 100% 100%;
  pointer-events: none; }

#container {
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; }

/* .shareModal {  
  height: 59%;
  text-align: center;
  //visibility: hidden;
  //opacity: 0;
  display: none;

  @include centerer();

  .shareBackground {
    height: 100%;
    display: block;
    width: auto;
  }

  .shareButtonWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 15% 5% 0%;
    box-sizing: border-box;

    .shareButton {
      padding: 2.7% 0;
      border-bottom: 1px solid #555555;
      display: block;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }

      &:first-child {
        padding-top: 0;
      }

      svg {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .shareClose{
    display: block;
    top: -10%;
    position: absolute;
    right: -3%;;
    width: auto;
    height: 4%;
    padding: 3.4%;
    cursor: pointer;
  }
} */
.shareModal {
  width: auto;
  height: 59%;
  background-color: #fdf3f3;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }
  .shareModal .shareModalHeading {
    background-color: #c068aa;
    height: 7.5%;
    width: auto;
    text-align: center; }
    .shareModal .shareModalHeading img {
      height: 100%;
      width: auto; }
  .shareModal .shareModalButtons {
    height: 92.5%;
    width: 100%;
    padding: 5%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }
    .shareModal .shareModalButtons .shareButton {
      height: 16.5%;
      display: block;
      padding: 3% 0;
      border-bottom: 1px solid #555555; }
      .shareModal .shareModalButtons .shareButton:last-child {
        border-bottom: none;
        padding-bottom: 0; }
      .shareModal .shareModalButtons .shareButton:first-child {
        padding-top: 0; }
      .shareModal .shareModalButtons .shareButton img {
        height: 100%;
        width: auto;
        display: block; }
  .shareModal .shareClose {
    display: block;
    top: -10%;
    position: absolute;
    right: -3%;
    width: auto;
    height: 4%;
    padding: 3.4%;
    cursor: pointer; }
